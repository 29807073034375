import React, { useState, useEffect } from "react";
import { Table, Spinner, Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Dashboard.css";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "../utils/axios";
import moment from "moment";
import { useHistory } from 'react-router-dom';
import NavSupplier from "../components/NavSupplier";
const PurchaseOrderListSupplier = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [poData, setPoData] = useState({});
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedPoNo, setSelectedPoNo] = useState("");

  const formatToRupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(number);
  };

  useEffect(() => {
    const getPOList = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/list/po/all/hotel`);
        console.log(response.data);
        setPoData(response.data);
      } catch (err) {
        console.error("Error finding PO list:", err);
      } finally {
        setLoading(false);
      }
    };
    getPOList();
  }, []);

  const renderTable = () => {
    if (!poData || Object.keys(poData).length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan="8" className="text-center">
              No data
            </td>
          </tr>
        </tbody>
      );
    }

    return Object.entries(poData).map(([poMasterNo, poList]) => (
      <React.Fragment key={poMasterNo}>
        <thead>
          <tr>
            <th colSpan="8" style={{ backgroundColor: "GrayText", color: "#fff" }}>{poMasterNo}</th>
          </tr>
          <tr>
            <th>No. PO</th>
            <th>For Date</th>
            <th>Hotel</th>
            <th>Supplier</th>
            <th>Total Cost</th>
            <th>Status</th>
            <th>Printed At</th>
            <th>File PO</th>
          </tr>
        </thead>
        <tbody>
          {poList.map((entry, index) => (
            <tr key={`${poMasterNo}-${index}`} className={entry.stts_po === 1 ? 'table-primary' : ''}>
              <td>{entry.no_po}</td>
              <td>{moment(entry.forDate).format('LL')}</td>
              <td>{entry.nm_hotel}</td>
              <td>{entry.name_supplier}</td>
              <td>{formatToRupiah(entry.grandTotalPO)}</td>
              <td>{entry.stts_po === 2 ? 'Revision' : 'Active'}</td>
              <td>{entry.created_at}</td>
              <td style={{ textAlign: "center" }}>
                <Button variant="info" onClick={() => handleViewPO(entry.link_po, entry.no_po)}>
                  <i className="bi bi-file-earmark-pdf"></i>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </React.Fragment>
    ));
  };

  const handleViewPO = (url, poNo) => {
    setSelectedPdfUrl(url);
    setSelectedPoNo(poNo);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdfUrl("");
    setSelectedPoNo("");
  };

  return (
    <>
      <NavSupplier />
      <div className="container mt-5">
        {loading && (
          <div className="text-center mt-5">
            <Spinner animation="border" role="status">
              <span>Loading...</span>
            </Spinner>
          </div>
        )}

        <Table bordered hover>
          {!loading && renderTable()}
        </Table>

        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>PO Document: {selectedPoNo}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src={selectedPdfUrl}
              width="100%"
              height="600px"
              title="PO Document"
            ></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default PurchaseOrderListSupplier;
